<template>
  <SpecialOffers></SpecialOffers>
</template>
<script>
import SpecialOffers from "../../../components/marketing/special-offers/SpecialOffers.vue";
export default {
  components: {
    SpecialOffers,
  },
};
</script>
