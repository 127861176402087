<template>
  <AddCourse></AddCourse>
</template>
<script>
import AddCourse from "../../../../components/studies/courses/Course/AddCourse.vue";
export default {
  components: {
    AddCourse,
  },
};
</script>
