<template>
  <Tests></Tests>
</template>
<script>
import Tests from "../../components/tests/index.vue";
export default {
  components: {
    Tests,
  },
};
</script>
