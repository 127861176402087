<!--
	This is the dashboard layout, used in dashboard, tables, billing and profile pages.
 -->

<template>
  <div>
    <!-- Dashboard Layout -->
    <a-layout
      class="layout-dashboard"
      id="layout-dashboard"
      :class="[
        navbarFixed ? 'navbar-fixed' : '',
        !sidebarCollapsed ? 'has-sidebar' : '',
        layoutClass,
      ]"
    >
      <!-- Main Sidebar -->
      <DashboardSidebar
        :sidebarCollapsed="sidebarCollapsed"
        :sidebarColor="sidebarColor"
        :sidebarTheme="sidebarTheme"
        @toggleSidebar="toggleSidebar"
      ></DashboardSidebar>
      <!-- / Main Sidebar -->

      <!-- Layout Content -->
      <a-layout>
        <!-- Layout Header's Conditionally Fixed Wrapper -->
        <DashboardHeader
          :sidebarCollapsed="sidebarCollapsed"
          :navbarFixed="navbarFixed"
          @toggleSidebar="toggleSidebar"
        ></DashboardHeader>
        <!-- / Layout Header's Conditionally Fixed Wrapper -->

        <!-- Page Content -->
        <a-layout-content>
          <router-view />
        </a-layout-content>
        <!-- / Page Content -->
        <operation></operation>

        <!-- Layout Footer -->
        <DashboardFooter></DashboardFooter>
        <!-- / Layout Footer -->

        <!-- Sidebar Overlay -->
        <div
          class="sidebar-overlay"
          @click="sidebarCollapsed = true"
          v-show="!sidebarCollapsed"
        ></div>
        <!-- / Sidebar Overlay -->
      </a-layout>
      <!-- / Layout Content -->
    </a-layout>
    <!-- / Dashboard Layout -->
  </div>
</template>

<script>
import DashboardSidebar from "../components/Sidebars/DashboardSidebar";
import DashboardHeader from "../components/Headers/DashboardHeader";
import DashboardFooter from "../components/Footers/Footer";
import { mapActions, mapGetters } from "vuex";
import operation from "../components/elements/success_operation";
import Cookie from "js-cookie";

export default {
  components: {
    DashboardSidebar,
    DashboardHeader,
    DashboardFooter,
    operation,
  },
  data() {
    return {
      // Sidebar collapsed status.
      sidebarCollapsed: false,

      // Main sidebar color.
      sidebarColor: "primary",

      // Main sidebar theme : light, white, dark.
      sidebarTheme: "light",

      // Header fixed status.
      navbarFixed: false,

      // Settings drawer visiblility status.
      showSettingsDrawer: false,
    };
  },
  beforeCreate() {
    let Cookies = Cookie.get();
    if (Cookies.token === undefined) {
      this.$store.commit("User/EXIT_USER");
    }
  },
  methods: {
    ...mapActions({
      exit: "User/exit",
    }),
    toggleSidebar(value) {
      this.sidebarCollapsed = value;
    },
    updateSidebarTheme(value) {
      this.sidebarTheme = value;
    },
  },
  watch: {
    getUserAuthStatus: {
      handler(val) {
        if (val === false) {
          this.$router.push({ path: "/login" });
        }
      },
      immediate: true,
    },
  },
  computed: {
    // Sets layout's element's class based on route's meta data.
    ...mapGetters({
      getUserAuthStatus: "User/getUserAuthStatus",
    }),
    layoutClass() {
      return this.$route.meta.layoutClass;
    },
  },
};
</script>

<style lang="scss"></style>
