<template>
  <Groups />
</template>

<script>
import Groups from "@/components/groups/index";
export default {
  components: {
    Groups
  }
}
</script>

<style scoped>

</style>