<template>
  <Students></Students>
</template>
<script>
import Students from "../../components/students/Students.vue";
export default {
  components: {
    Students,
  },
};
</script>
