<template>
  <GroupsEdit />
</template>

<script>
import GroupsEdit from "@/components/groups/edit";
export default {
  components: {
    GroupsEdit
  }
}
</script>

<style scoped>

</style>