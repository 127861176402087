<template>
  <Calendar></Calendar>
</template>
<script>
import Calendar from "../../components/calendar/add-edit/edit";
export default {
  components: {
    Calendar,
  },
};
</script>
