<template>
  <Users></Users>
</template>
<script>
import Users from "../../components/users/EditUser.vue";
export default {
  components: {
    Users,
  },
};
</script>
