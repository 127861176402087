<template>
  <Homeworks></Homeworks>
</template>
<script>
import Homeworks from "../../components/homeworks/Homeworks.vue";
export default {
  components: {
    Homeworks,
  },
};
</script>
