var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-form-model-item',{attrs:{"label":"Статус"}},[(_vm.homework.status === 'In_progress')?_c('div',[_vm._v("На проверке")]):_vm._e(),(_vm.homework.status === 'Complete')?_c('div',[_vm._v("Принято")]):_vm._e(),(_vm.homework.status === 'Failed')?_c('div',[_vm._v("Отклонено")]):_vm._e()]),_c('a-form-model-item',{attrs:{"label":"Студент"}},[_c('div',{staticStyle:{"cursor":"pointer"}},[_c('div',{on:{"click":function($event){return _vm.goTo('/students/' + _vm.homework.user.id)}}},[_vm._v(" "+_vm._s(_vm.homework.user.lastname)+" "+_vm._s(_vm.homework.user.firstname)+" ")])])]),_c('a-row',{staticClass:"bottom-buttons",attrs:{"type":"flex","gutter":24}},[_c('a-col',{attrs:{"span":24,"lg":8,"md":24}},[_c('a-form-model-item',{attrs:{"label":"Курс"}},[_c('div',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.goTo('/courses/' + _vm.homework.study.course_id)}}},[_vm._v(" "+_vm._s(_vm.homework.study.course_name)+" ")])])],1),_c('a-col',{attrs:{"span":24,"lg":8,"md":24}},[_c('a-form-model-item',{attrs:{"label":"Модуль"}},[_c('div',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.goTo(
              '/courses/' +
                _vm.homework.study.course_id +
                '/modules/' +
                _vm.homework.study.module_id
            )}}},[_vm._v(" "+_vm._s(_vm.homework.study.module_name)+" ")])])],1),_c('a-col',{attrs:{"span":24,"lg":8,"md":24}},[_c('a-form-model-item',{attrs:{"label":"Урок"}},[_c('div',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.goTo(
              '/courses/' +
                _vm.homework.study.course_id +
                '/modules/' +
                _vm.homework.study.module_id +
                '/lessons/' +
                _vm.homework.study.lesson_id
            )}}},[_vm._v(" "+_vm._s(_vm.homework.study.lesson_name)+" ")])])],1)],1),_c('a-form-model-item',{attrs:{"label":"Ответ студента"}},[_vm._v(" "+_vm._s(_vm.homework.text)+" ")]),_c('a-form-model-item',{attrs:{"label":"Файлы прикрепленные к Д/З"}},[_c('ul',_vm._l((_vm.homework.homeworkfiles_set),function(file,index){return _c('li',{key:index},[(_vm.homework.homeworkfiles_set.length > 0)?_c('a',{attrs:{"href":_vm.config.basicImageURL + file.file}},[_vm._v("Файл "+_vm._s(index + 1))]):_vm._e()])}),0),(_vm.homework.homeworkfiles_set.length === 0)?_c('p',[_vm._v(" К домашнему заданию файлов не прикреплено ")]):_vm._e()]),_c('a-form-model-item',{attrs:{"label":"Коментарий к домашнему заданию"}},[(_vm.homework.status === 'In_progress')?_c('a-textarea',{attrs:{"rows":"4"},model:{value:(_vm.homework.review),callback:function ($$v) {_vm.$set(_vm.homework, "review", $$v)},expression:"homework.review"}}):_vm._e(),_vm._l((_vm.homework.review),function(review,key){return (_vm.homework.status !== 'In_progress')?_c('p',[_vm._v(_vm._s(review.review))]):_vm._e()})],2),(_vm.homework.status === 'In_progress')?_c('a-row',{staticClass:"buttons__block",attrs:{"type":"flex","gutter":24}},[_c('a-col',{attrs:{"span":24,"lg":12,"md":24}},[_c('a-button',{staticClass:"button",attrs:{"type":"primary"},on:{"click":function($event){return _vm.changeStatusHomeWork('Complete')}}},[_vm._v("Принять")])],1),_c('a-col',{attrs:{"span":24,"lg":12,"md":24}},[_c('a-button',{staticClass:"button",attrs:{"type":"danger"},on:{"click":function($event){return _vm.changeStatusHomeWork('Failed')}}},[_vm._v("Отклонить")])],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }