<template>
  <Marketing></Marketing>
</template>
<script>
import Marketing from "../../components/marketing/Marketing.vue";
export default {
  components: {
    Marketing,
  },
};
</script>
