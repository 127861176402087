<template>
  <GroupsAdd />
</template>

<script>
import GroupsAdd from "@/components/groups/add";
export default {
  components: {
    GroupsAdd
  }
}
</script>

<style scoped>

</style>