<template>
  <PushNotifications></PushNotifications>
</template>
<script>
import PushNotifications from "../../../components/marketing/push-notifications/PushNotifications.vue";
export default {
  components: {
    PushNotifications,
  },
};
</script>
