<template>
  <Courses></Courses>
</template>
<script>
import Courses from "../../../components/studies/courses/Courses.vue";
export default {
  components: {
    Courses,
  },
};
</script>
